<script setup>
  import { getCurrentInstance, provide, ref, watch, watchEffect } from 'vue';
  import Banner from '@/components/Banner.vue';
  import OrderItemsTable from '@/views/order/OrderItemsTable.vue';
  import { DEFAULT_ORDER } from '@/constants/DEFAULT_ORDER';
  import OrderReceiverFields from '@/views/order/OrderReceiverFields.vue';
  import OrderFields from '@/views/order/OrderFields.vue';
  import ShipmentFields from '@/views/order/ShipmentFields.vue';
  import PackageFields from '@/views/order/PackageFields.vue';
  import FormPageFooter from '@/components/form/FormPageFooter.vue';
  import { useOrderCourier } from '@/composables/order/useOrderCourier';
  import PrintControls from '@/components/PrintControls.vue';
  import { useOrderErrors } from '@/composables/order/useOrderErrors';
  import StatusInfo from '@/components/properties/StatusInfo.vue';

  const instance = getCurrentInstance();
  const store = instance.proxy.$store;
  const buefy = instance.proxy.$buefy;
  const router = instance.proxy.$router;
  const route = instance.proxy.$route;

  const order = ref(structuredClone(DEFAULT_ORDER));
  const isLoading = ref(true);

  const { errors, handleFormValidationErrors } = useOrderErrors(order);
  const { filteredCouriers, shipmentTypes, selectedCourierMeta, activeCouriers, defaultShipmentTypeOfSelectedCourier } =
    useOrderCourier(order);

  const startWatchingForCourierChanges = () => {
    watch(
      () => order.value.shipment.courier_id,
      () => {
        order.value.shipment.courier_fields = {};
        order.value.shipment.shipment_type = defaultShipmentTypeOfSelectedCourier.value;
      },
    );
  };

  const fetchOrder = async orderId => {
    isLoading.value = true;

    try {
      const existingOrder = await store.dispatch('order/getOrder', orderId);
      const storeClone = structuredClone(existingOrder);

      // Convert date strings to Date objects
      ['created_at', 'order_date', 'updated_at'].forEach(date => {
        storeClone[date] = new Date(storeClone[date]);
      });

      await store.dispatch('courier/init');
      const courier = store.getters['courier/courierById'](storeClone.shipment.courier_id);

      // Remove extra properties when duplicating
      if (route.query.duplicate) {
        const defaultOrder = structuredClone(DEFAULT_ORDER);
        Object.keys(storeClone).forEach(key => {
          if (!(key in defaultOrder)) delete storeClone[key];
        });
      }

      order.value = Object.assign({}, storeClone);

      // Set courier and shipment details
      order.value.shipment = {
        ...order.value.shipment,
        courier_id: courier?.id || filteredCouriers.value[0].id,
        shipment_type: storeClone.shipment.shipment_type || defaultShipmentTypeOfSelectedCourier.value,
        courier_fields: order.value.shipment.courier_fields || {},
      };

      // Ensure order items are set
      order.value.order_items = storeClone.order_items || [];

      startWatchingForCourierChanges();
    } finally {
      isLoading.value = false;
    }
  };
  watchEffect(() => {
    if (instance.proxy.$route.params.orderId) {
      fetchOrder(instance.proxy.$route.params.orderId);
    } else {
      store
        .dispatch('courier/init')
        .then(() => {
          if (filteredCouriers.value?.[0]?.id) {
            order.value.shipment.courier_id = filteredCouriers.value[0].id;
            order.value.shipment.shipment_type =
              shipmentTypes.value.find(type => type.is_default)?.id || shipmentTypes.value[0]?.id || null;
          }
          startWatchingForCourierChanges();
        })
        .finally(() => {
          isLoading.value = false;
        });
    }
  });

  const getCreationMessage = () => {
    if (instance.proxy.$route.query.duplicate) return 'Bestelling gedupliceerd';
    if (instance.proxy.$route.params.orderId) return 'Bestelling aangepast';
    return 'Zending aangemaakt';
  };

  const handleCreateOrUpdateOrder = () => {
    const action =
      instance.proxy.$route.params.orderId && !instance.proxy.$route.query.duplicate
        ? 'order/updateOrder'
        : 'order/createOrder';
    const message = getCreationMessage();

    store
      .dispatch(action, order.value)
      .then(value => {
        buefy.snackbar.open({
          queue: false,
          duration: 10000,
          message,
          type: 'is-success',
        });
        if (value.status_info.length > 0 && !route.params.orderId) {
          console.log('status info', JSON.stringify(value.status_info));
          router.push({ name: 'order', params: { orderId: value.id } });
        } else {
          if (instance.proxy.$route.params.orderId) {
            return router.push({ name: 'home', params: { activeTabName: 'order' } });
          }

          errors.value = {};

          // Reset the order form, keep the courier and shipment type
          const resetOrder = structuredClone(DEFAULT_ORDER);
          order.value = {
            ...resetOrder,
            shipment: {
              ...resetOrder.shipment,
              courier_id: order.value.shipment.courier_id,
              shipment_type: order.value.shipment.shipment_type,
            },
          };

          window.scrollTo({ top: 0, behavior: 'smooth' });
        }
      })
      .catch(error => {
        handleFormValidationErrors(error.response);
        buefy.toast.open({
          message: 'Ongeldige invoer, controleer de velden',
          type: 'is-warning',
        });
      })
      .finally(() => {
        isLoading.value = false;
      });
  };

  provide('order', order);
  provide('errors', errors);
  provide('selectedCourierMeta', selectedCourierMeta);
</script>

<template>
  <main
    class="tw-prose prose-h2:tw-text-center sm:prose-h2:tw-text-left prose-h1:tw-text-xl sm:prose-h1:tw-text-4xl tw-max-w-none tw-mb-96"
  >
    <header
      class="tw-border-solid tw-border-x-0 tw-border-t-0 tw-border-b-2 tw-border-primary tw-py-4 tw-sticky tw-top-20 tw-bg-white tw-z-20"
    >
      <div class="tw-container tw-space-y-4 tw-max-w-screen-2xl">
        <div class="tw-flex tw-items-center tw-gap-4 tw-space-y-4">
          <nav>
            <b-button
              icon-left="arrow-left"
              @click="$router.push({ name: 'home', params: { activeTabName: 'order' } })"
            >
              Terug
            </b-button>
          </nav>

          <h1 v-if="$route.query.duplicate" class="!tw-m-0">Bestelling dupliceren</h1>
          <h1 v-else-if="$route.params.orderId" class="!tw-m-0">Bestelling aanpassen</h1>
          <h1 v-else class="!tw-m-0">Nieuwe zending</h1>
        </div>

        <banner
          v-if="activeCouriers.length < 1"
          bannerText="Let op! Je hebt geen vervoerders gekoppeld. Koppel een vervoerder zodat de order volledig ingevuld kan worden, wat nodig is om een label te creëren"
          :buttonText="'Vervoerder(s) toevoegen'"
          class="tw-not-prose"
          :href="'/settings/services/courier'"
          :type="'is-warning'"
        />
      </div>
    </header>

    <form class="sm:tw-container tw-relative">
      <b-loading v-model="isLoading" can-cancel is-full-page />
      <div class="tw-not-prose">
        <status-info v-if="order.status_info" container-class="block" :info="order.status_info"></status-info>
      </div>
      <div class="tw-grid sm:tw-grid-cols-2 tw-gap-8">
        <section>
          <h2
            class="tw-sticky sm:tw-static tw-border-b sm:tw-border-none tw-border-solid tw-border-t-0 tw-border-x-0 tw-py-2 sm:tw-pt-0 tw-border-primary tw-top-[150px] tw-bg-white tw-z-10"
          >
            Ontvanger
          </h2>
          <OrderReceiverFields class="tw-not-prose tw-container sm:tw-px-0" />
        </section>
        <div>
          <section>
            <h2
              class="tw-sticky sm:tw-static tw-border-b sm:tw-border-none tw-border-solid tw-border-t-0 tw-border-x-0 tw-py-2 sm:tw-pt-0 tw-border-primary tw-top-[150px] tw-bg-white tw-z-10"
            >
              Order
            </h2>
            <OrderFields class="tw-not-prose tw-container sm:tw-px-0" />
          </section>
          <section>
            <h2
              class="tw-sticky sm:tw-static tw-border-b sm:tw-border-none tw-border-solid tw-border-t-0 tw-border-x-0 tw-py-2 sm:tw-pt-0 tw-border-primary tw-top-[150px] tw-bg-white tw-z-10"
            >
              Zending
            </h2>
            <ShipmentFields class="tw-not-prose tw-container sm:tw-px-0" />
          </section>
          <section>
            <h2
              class="tw-sticky sm:tw-static tw-border-b sm:tw-border-none tw-border-solid tw-border-t-0 tw-border-x-0 tw-py-2 sm:tw-pt-0 tw-border-primary tw-top-[150px] tw-bg-white tw-z-10"
            >
              {{ order.shipment.packages.length > 1 ? 'Pakketten' : 'Pakket' }}
            </h2>
            <PackageFields class="tw-not-prose tw-container sm:tw-px-0" />
          </section>
        </div>
      </div>

      <section>
        <OrderItemsTable />
      </section>

      <nav class="tw-not-prose">
        <form-page-footer class="tw-z-40 tw-relative">
          <b-button @click="handleCreateOrUpdateOrder">
            <span class="tw-inline-flex tw-items-center tw-gap-1">
              <font-awesome-icon fixed-width icon="save" />
              <span class="tw-sr-only sm:tw-not-sr-only">
                <span v-if="$route.query.duplicate">Bestelling dupliceren</span>
                <span v-else-if="$route.params.orderId">Bestelling aanpassen</span>
                <span v-else>Zending opslaan</span>
              </span>
            </span>
          </b-button>
          <b-button @click="$router.push({ name: 'home', params: { activeTabName: 'order' } })">
            <span class="tw-inline-flex tw-items-center tw-gap-1">
              <font-awesome-icon class="tw-w-4 tw-h-4" icon="times" />
              <span class="tw-sr-only md:tw-not-sr-only">Annuleren</span>
            </span>
          </b-button>
          <print-controls
            class="!tw-m-0"
            context="order"
            :order="order"
            :order-ids="$route.params.orderId && !$route.query.duplicate ? [$route.params.orderId] : undefined"
          />
        </form-page-footer>
      </nav>
    </form>
  </main>
</template>
