<template>
  <div>
    <b-field>
      <p class="control">
        <b-tooltip type="is-dark">
          <b-button class="tw-p-3" @click="isShowingPrinterSettings = true">
            <span class="tw-flex tw-items-center tw-gap-1">
              <font-awesome-icon fixed-width icon="gear" />
              <span class="tw-sr-only">Label Instellingen</span>
            </span>
          </b-button>
          <template #content> Label instellingen</template>
        </b-tooltip>
      </p>
      <p class="control">
        <b-tooltip type="is-dark">
          <template #content> Pagina indeling</template>
          <b-button
            v-if="preferences.label_settings.format === 'a4'"
            class="tw-p-3"
            @click="isShowingFirstPageSettings = true"
          >
            <span class="tw-flex tw-items-center tw-gap-2">
              <span class="tw-sr-only"> Indeling van de eerste pagina </span>
              <div class="tw-grid tw-grid-cols-2 tw-gap-0.5 tw-w-5 tw-h-5">
                <div
                  class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
                  :class="{ 'tw-bg-black': confirmedPositions.upperLeft }"
                ></div>
                <div
                  class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
                  :class="{ 'tw-bg-black': confirmedPositions.upperRight }"
                ></div>
                <div
                  class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
                  :class="{ 'tw-bg-black': confirmedPositions.lowerLeft }"
                ></div>
                <div
                  class="tw-border tw-aspect-square tw-border-solid tw-border-black tw-rounded-sm"
                  :class="{ 'tw-bg-black': confirmedPositions.lowerRight }"
                ></div>
              </div>
            </span>
          </b-button>
        </b-tooltip>
      </p>

      <p class="control">
        <b-button
          class="!tw-p-4 tw-flex tw-items-center tw-gap-2"
          :class="{
            'is-loading': isLoading,
            'is-primary': ['order_create', 'order'].includes($route.name),
          }"
          :disabled="isLoading || (!hasLabelIds && !hasOrderIds && !order)"
          type="button"
          @click="handlePrintingOrDownloadingOfLabels"
        >
          <span class="tw-flex tw-items-center tw-gap-1">
            <font-awesome-icon
              v-if="printerClientIsReady && preferences.label_settings.mode === 'printer'"
              fixed-width
              icon="print"
            />
            <font-awesome-icon v-else fixed-width icon="download" />
            <span class="tw-sr-only md:tw-not-sr-only first-letter:tw-uppercase">{{ getActionText() }}</span>
          </span>
        </b-button>
      </p>
      <p class="control">
        <b-dropdown :position="['order', 'order_create'].includes(route.name) ? 'is-top-left' : undefined">
          <template #trigger>
            <b-tooltip multilined type="is-dark">
              <template #content>Extra label aanmaken, export document downloaden, of pakbonnen downloaden</template>
              <b-button class="tw-p-3">
                <span class="tw-flex tw-items-center tw-gap-1">
                  <font-awesome-icon class="tw-h-5 tw-w-5" icon="ellipsis-v" />
                  <span class="tw-sr-only"
                    >Extra label aanmaken, export document downloaden, of pakbonnen downloaden</span
                  >
                </span>
              </b-button>
            </b-tooltip>
          </template>

          <b-dropdown-item
            v-if="context !== 'order'"
            :disabled="isLoading || !(hasOrderIds && hasLabelIds)"
            @click="handleExtraLabelCreation"
          >
            <span class="tw-flex tw-items-center tw-gap-1">
              <font-awesome-icon fixed-width icon="copy" />
              <span class="tw-whitespace-nowrap">Extra label aanmaken</span>
            </span>
          </b-dropdown-item>

          <b-tooltip
            :active="
              Boolean((hasOrderIds || props.order?.id || hasLabelIds) && exportDocumentGenerationNotAllowedReason)
            "
            append-to-body
            multilined
            position="is-right"
            type="is-dark"
          >
            <template #content>
              {{ exportDocumentGenerationNotAllowedReason }}
            </template>
            <b-dropdown-item
              :disabled="Boolean(exportDocumentGenerationNotAllowedReason)"
              @click="handleDownloadingExportDocument"
            >
              <span class="tw-flex tw-items-center tw-gap-1">
                <font-awesome-icon fixed-width icon="download" />
                <span class="tw-whitespace-nowrap first-letter:tw-uppercase">
                  {{ getActionText() }} en export document downloaden
                </span>
              </span>
            </b-dropdown-item>
          </b-tooltip>

          <b-dropdown-item :disabled="!hasOrderIds && !hasLabelIds" @click="downloadPackingSlips">
            <span class="tw-flex tw-items-center tw-gap-1">
              <font-awesome-icon fixed-width icon="download" />
              <span class="tw-whitespace-nowrap"> Pakbonnen downloaden</span>
            </span>
          </b-dropdown-item>
        </b-dropdown>
      </p>
      <b-modal :active.sync="isShowingFirstPageSettings" has-modal-card>
        <form @submit.prevent="submitPosition">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Indeling van de eerste pagina</p>
              <button class="delete" type="button" @click="closeFirstPageSettings" />
            </header>

            <section class="modal-card-body">
              <p>
                Hier kun je aangeven op welke plaatsen van de eerst volgende pagina de labels geplaatst moeten worden.
                De overige labels worden niet op deze pagina geplaatst, maar gaan direct naar de volgende pagina. De
                labels worden van links naar rechts en van boven naar beneden geplaatst. Je kunt meerdere posities
                selecteren.
              </p>
              <br />
              <p>
                <strong>Let op:</strong> Deze instelling schakelt zichzelf uit zodra je begint met downloaden of
                printen.
              </p>
              <div class="tw-grid tw-grid-cols-[1fr,1fr] tw-gap-4 tw-aspect-square tw-w-2/3 md:tw-w-1/2 tw-p-4">
                <button
                  class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                  type="button"
                  @click="handlePositionClick(POSITIONS.upperLeft)"
                >
                  <font-awesome-icon
                    class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                    :class="{
                      'tw-opacity-100': positions.upperLeft,
                      'group-hover:tw-opacity-50': positions.upperLeft,
                    }"
                    icon="check-circle"
                  />
                  <font-awesome-icon
                    class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                    :class="{
                      'group-hover:tw-opacity-60': positions.upperLeft,
                    }"
                    icon="times"
                  />
                </button>

                <button
                  class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                  type="button"
                  @click="handlePositionClick(POSITIONS.upperRight)"
                >
                  <font-awesome-icon
                    class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                    :class="{
                      'tw-opacity-100': positions.upperRight,
                      'group-hover:tw-opacity-50': positions.upperRight,
                    }"
                    icon="check-circle"
                  />
                  <font-awesome-icon
                    class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                    :class="{
                      'group-hover:tw-opacity-60': positions.upperRight,
                    }"
                    icon="times"
                  />
                </button>

                <button
                  class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                  type="button"
                  @click="handlePositionClick(POSITIONS.lowerLeft)"
                >
                  <font-awesome-icon
                    class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                    :class="{
                      'tw-opacity-100': positions.lowerLeft,
                      'group-hover:tw-opacity-50': positions.lowerLeft,
                    }"
                    icon="check-circle"
                  />
                  <font-awesome-icon
                    class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                    :class="{
                      'group-hover:tw-opacity-60': positions.lowerLeft,
                    }"
                    icon="times"
                  />
                </button>

                <button
                  class="tw-bg-zinc-100 tw-cursor-pointer tw-group tw-border-4 tw-border-dotted tw-aspect-[1/1.414] tw-place-items-center tw-grid"
                  type="button"
                  @click="handlePositionClick(POSITIONS.lowerRight)"
                >
                  <font-awesome-icon
                    class="has-text-success tw-transition-opacity tw-relative tw-col-start-1 tw-row-start-1 tw-opacity-0 tw-size-1/2"
                    :class="{
                      'tw-opacity-100': positions.lowerRight,
                      'group-hover:tw-opacity-50': positions.lowerRight,
                    }"
                    icon="check-circle"
                  />
                  <font-awesome-icon
                    class="tw-col-start-1 tw-transition-opacity tw-relative tw-size-3/4 tw-z-10 tw-opacity-0 tw-row-start-1"
                    :class="{
                      'group-hover:tw-opacity-60': positions.lowerRight,
                    }"
                    icon="times"
                  />
                </button>
              </div>
            </section>

            <footer class="modal-card-foot tw-flex tw-justify-between">
              <button class="button" type="button" @click="closeFirstPageSettings">Annuleren</button>
              <button class="button is-success" type="submit">Bevestigen</button>
            </footer>
          </div>
        </form>
      </b-modal>
      <b-modal :active.sync="isShowingPrinterSettings" has-modal-card>
        <form @submit.prevent="updateLabelSettings">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Label instellingen</p>
              <button class="delete" type="button" @click="closeLabelSettings" />
            </header>
            <section class="modal-card-body">
              <div class="tw-flex md:tw-flex-row tw-flex-col tw-gap-4 md:tw-gap-8">
                <b-field label="Papierformaat">
                  <b-radio-button v-model="form.format" native-value="a4" type="is-success"
                    >A4 (4 per pagina)
                  </b-radio-button>
                  <b-radio-button v-model="form.format" native-value="a6" type="is-success"
                    >A6 (1 per pagina)
                  </b-radio-button>
                </b-field>
                <b-field label="Modus">
                  <b-radio-button v-model="form.mode" native-value="pdf" type="is-success">Download</b-radio-button>
                  <b-radio-button
                    v-if="printerClientIsReady"
                    v-model="form.mode"
                    native-value="printer"
                    type="is-success"
                    >Printer
                  </b-radio-button>
                  <p v-else class="control">
                    <b-button @click="goToPrinterSettings">Printer instellen</b-button>
                  </p>
                </b-field>
              </div>
            </section>
            <footer class="modal-card-foot tw-flex tw-justify-between">
              <button class="button" type="button" @click="closeLabelSettings">Annuleren</button>
              <button class="button is-success" type="submit">Opslaan</button>
            </footer>
          </div>
        </form>
      </b-modal>
    </b-field>
  </div>
</template>

<script setup>
  import { computed, getCurrentInstance, onBeforeMount, reactive, ref, watchEffect } from 'vue';
  import { download, printLabel, reportLabelError, reportLabelWarning } from '@/utils/functions';
  import { AbortPrintException, ApiError } from '@/utils/errors';
  import LabelSelectorModal from '@/components/modals/LabelSelectorModal.vue';
  import EU_COUNTRIES from '@/constants/EU_COUNTRIES';
  import { usePreferences } from '@/composables/usePreferences';

  const instance = getCurrentInstance();
  const store = instance.proxy.$store;
  const router = instance.proxy.$router;
  const route = instance.proxy.$route;
  const buefy = instance.proxy.$buefy;

  const POSITIONS = {
    upperLeft: 'upperLeft',
    upperRight: 'upperRight',
    lowerLeft: 'lowerLeft',
    lowerRight: 'lowerRight',
  };

  const { preferences } = usePreferences();

  const emit = defineEmits(['afterPrintOrDownload', 'afterLabelCreate', 'afterOrderCreate']);

  const props = defineProps({
    context: {
      type: String,
      required: true,
      validator: value => ['order', 'label', 'scan'].includes(value),
    },
    labelIds: {
      type: Array,
      default: () => [],
    },
    orderIds: {
      type: Array,
      default: () => [],
    },
    parameters: {
      type: Object,
      default: () => ({}),
    },
    order: {
      type: Object,
    },
  });

  const isLoading = ref(false);
  const form = ref(null);
  const positions = reactive({
    upperLeft: false,
    upperRight: false,
    lowerLeft: false,
    lowerRight: false,
  });
  const confirmedPositions = reactive({
    upperLeft: false,
    upperRight: false,
    lowerLeft: false,
    lowerRight: false,
  });
  const isShowingPrinterSettings = ref(false);
  const isShowingFirstPageSettings = ref(false);

  const printerClientIsReady = computed(() => store.getters['app/printerClientIsReady']);

  const hasOrderIds = computed(() => props.orderIds?.length > 0);
  const hasLabelIds = computed(() => props.labelIds?.length > 0);

  const handleDownloadingExportDocument = async () => {
    isLoading.value = true;

    // Get label IDs from order or use existing label IDs
    const labelIdsToDownload =
      props.order?.id || hasOrderIds
        ? await createOrderLabels({ orderIds: props.order ? [props.order.id] : props.orderIds })
        : props.labelIds;

    if (labelIdsToDownload.length === 0) {
      isLoading.value = false;
      return;
    }

    try {
      // Handle labels first
      await printOrDownload(labelIdsToDownload);

      // Download export documents
      const docs = await Promise.all(
        labelIdsToDownload.map(labelId => store.dispatch('label/getExportDoc', { labelId })),
      );

      // Save each document with appropriate name
      docs.forEach((doc, index) => {
        const order = props.order || store.state.order.all.find(o => o.id === labelIdsToDownload[index]);
        const fileName = `export_doc_${order?.order_number || labelIdsToDownload[index]}.pdf`;
        download(fileName, doc, 'application/pdf', 'base64');
      });

      // Update application state
      await Promise.all([
        store.dispatch('account/getLabelCount'),
        store.dispatch('order/reloadViews', { silent: false }),
      ]);
    } finally {
      // Reset state
      isLoading.value = false;

      // Reset all position flags
      Object.keys(confirmedPositions).forEach(key => {
        confirmedPositions[key] = false;
        positions[key] = false;
      });
    }
  };

  //ensures orders are available in store, its not elegant but it works
  watchEffect(async () => {
    if (props.context !== 'order')
      for (const orderId of props.orderIds) {
        store.dispatch('order/getOrder', orderId);
      }
  });

  const exportDocumentGenerationNotAllowedReason = computed(() => {
    const REQUIRED_FIELDS_FOR_EXPORT = [
      'category',
      'description',
      'hs_code',
      'name',
      'product_country',
      'quantity',
      'sku',
      'unit_price_inc_btw',
      'weight',
    ];

    const orders = props.order
      ? [props.order]
      : props.orderIds?.length
        ? store.state.order.all.filter(o => props.orderIds.includes(o.id))
        : [];

    if (!props.labelIds?.length && orders.length === 0) {
      return orders.length === 0 ? 'Order niet gevonden.' : null;
    }

    for (const order of orders) {
      const type = hasLabelIds.value ? 'label' : 'bestelling';
      if (EU_COUNTRIES.includes(order.country))
        return `Op zijn minst 1 geselecteerde ${type} gaat naar een EU land. Landen binnenin de EU hoeven geen export document te hebben`;
      if (order.status_info?.length > 0) return `Op zijn minst 1 geselecteerde ${type} heeft validatie problemen.`;
      if (order.validators?.is_export && order.order_items?.length === 0)
        return `Op zijn minst 1 geselecteerde ${type} is een export order, maar heeft geen order items.`;
      if (order.order_items?.length === 0) return `Op zijn minst 1 geselecteerde ${type} heeft geen order items.`;

      const missingFields = order.order_items.filter(item => !REQUIRED_FIELDS_FOR_EXPORT.every(field => item[field]));
      if (missingFields.length > 0)
        return `Op zijn minst 1 geselecteerde ${type} heeft niet alle verplichte velden ingevuld.`;
    }

    return null;
  });

  const handleLabelWarning = async (error, orderIds) => {
    return await new Promise(resolve => {
      reportLabelWarning(error.response, {
        cancelText: 'Annuleren',
        confirmText: 'Labels aanmaken',
        onCancel: () => {
          resolve([]);
        },
        onConfirm: async () => {
          try {
            resolve(await createOrderLabels({ orderIds, force: true }));
          } catch (error) {
            if (!(error instanceof AbortPrintException)) throw error;
          } finally {
            resolve([]);
          }
        },
      });
    });
  };

  const printOrDownload = async labelIds => {
    if (labelIds.length === 0) return;
    const result = await printLabel({
      labelIds,
      target: printerClientIsReady.value ? preferences.value.label_settings.mode : 'pdf',
      format: preferences.value.label_settings.format,
      positions: confirmedPositions,
      ...props.parameters,
    });
    emit('afterPrintOrDownload', result);
    return result;
  };

  const createOrderLabels = async ({ orderIds = [], force = false }) => {
    try {
      const result = await store.dispatch('label/createLabels', {
        orderIds: orderIds,
        force,
        scan: props.context === 'scan',
      });

      if (result.errors.length > 0) {
        void reportLabelError(result);
      }

      emit('afterLabelCreate', result);
      return orderIds.map(id => result.data.find(label => label.order_id === id)?.id).filter(label => !!label);
    } catch (error) {
      if (error instanceof ApiError) {
        if (error.response.status === 'warning') {
          return await handleLabelWarning(error, orderIds);
        } else {
          reportLabelError(error.response);
          return [];
        }
      } else if (!(error instanceof AbortPrintException)) {
        throw error;
      } else {
        return [];
      }
    }
  };

  const handleExtraLabelCreation = async () => {
    isLoading.value = true;
    const labelIds = await createOrderLabels({ orderIds: props.orderIds });

    if (labelIds.length > 0) {
      await printOrDownload(labelIds);

      await store.dispatch('account/getLabelCount');
      await store.dispatch('order/reloadViews', { silent: false }, { root: true });
    }

    isLoading.value = false;
    confirmedPositions.upperLeft = false;
    confirmedPositions.upperRight = false;
    confirmedPositions.lowerLeft = false;
    confirmedPositions.lowerRight = false;

    positions.upperLeft = false;
    positions.upperRight = false;
    positions.lowerLeft = false;
    positions.lowerRight = false;
  };

  const handlePrintingOrDownloadingOfLabels = async () => {
    isLoading.value = true;
    let labelIds = structuredClone(props.labelIds);

    try {
      if (props.context === 'scan' && labelIds.length > 1) {
        labelIds = await new Promise(resolve => {
          buefy.modal.open({
            component: LabelSelectorModal,
            parent: instance.proxy,
            props: {
              labelIds,
              onCancel: () => {
                resolve([]);
              },
              onConfirm: async selectedLabelIds => {
                resolve(selectedLabelIds);
              },
            },
          });
        });
      }

      if ((props.context !== 'label' && hasOrderIds.value && !hasLabelIds.value) || props.order) {
        let order = structuredClone(props.order);
        if (props.order) {
          if (props.order?.id) {
            // Update existing order if ID exists
            order = await store.dispatch('order/updateOrder', props.order);
            emit('afterOrderCreate', order);
          } else {
            // Create new order if no ID exists
            order = await store.dispatch('order/createOrder', props.order);
            emit('afterOrderCreate', order);
          }
        }
        const orderIds = order ? [order.id] : props.orderIds;
        labelIds = await createOrderLabels({ orderIds });
      }

      if (labelIds.length > 0) {
        await printOrDownload(labelIds);

        await store.dispatch('account/getLabelCount');
        await store.dispatch('order/reloadViews', { silent: false }, { root: true });
      }
    } finally {
      isLoading.value = false;
      confirmedPositions.upperLeft = false;
      confirmedPositions.upperRight = false;
      confirmedPositions.lowerLeft = false;
      confirmedPositions.lowerRight = false;

      positions.upperLeft = false;
      positions.upperRight = false;
      positions.lowerLeft = false;
      positions.lowerRight = false;
      if (props.order && labelIds.length > 0)
        router.push({
          name: 'label',
          params: {
            labelId: labelIds[0],
          },
        });
    }
  };

  const reset = async () => {
    form.value = structuredClone(preferences.value.label_settings);
  };
  const closeLabelSettings = () => {
    reset();
    isShowingPrinterSettings.value = false;
  };

  const closeFirstPageSettings = () => {
    isShowingFirstPageSettings.value = false;
    positions.upperLeft = confirmedPositions.upperLeft;
    positions.upperRight = confirmedPositions.upperRight;
    positions.lowerLeft = confirmedPositions.lowerLeft;
    positions.lowerRight = confirmedPositions.lowerRight;
  };
  const submitPosition = () => {
    confirmedPositions.upperLeft = positions.upperLeft;
    confirmedPositions.upperRight = positions.upperRight;
    confirmedPositions.lowerLeft = positions.lowerLeft;
    confirmedPositions.lowerRight = positions.lowerRight;
    isShowingFirstPageSettings.value = false;
  };
  const goToPrinterSettings = () => {
    isShowingPrinterSettings.value = false;
    setTimeout(() => {
      router.push({ name: 'printer' });
    }, 250);
  };
  const handlePositionClick = position => {
    positions[position] = !positions[position];
  };
  const updateLabelSettings = async () => {
    preferences.value.label_settings = structuredClone(form.value);
    isShowingPrinterSettings.value = false;
  };
  const getActionText = () => {
    const isPrinting = printerClientIsReady.value && preferences.value.label_settings.mode === 'printer';
    const action = isPrinting ? 'printen' : 'downloaden';

    let prefix = 'label';
    if (props.orderIds.length > 1) prefix = 'labels';

    if (props.order) {
      if (route.query.duplicate) prefix = 'zending dupliceren';
      else if (route.params.orderId) prefix = 'bestelling aanpassen';
      else prefix = 'zending aanmaken';
      return `${prefix} en ${action}`;
    }

    if (props.context === 'order' && props.labelIds.length === 0) {
      prefix = props.orderIds.length > 1 ? 'labels aanmaken' : 'label aanmaken';
      return `${prefix} en ${action}`;
    }

    return `${prefix} ${action}`;
  };

  const downloadPackingSlips = async () => {
    const orders = store.state.order.all.filter(o => props.orderIds.includes(o.id));
    const orderNumbers = orders.map(o => o.order_number);
    const orderIds = orders.map(o => o.id);

    const message = `Weet je zeker dat je voor deze ${orderNumbers.length} ${orderNumbers.length > 1 ? 'orders' : 'order'} een pakbon wil downloaden? <br><br><ul class="tw-list-disc tw-list-inside tw-max-h-96 tw-overflow-y-auto">${orderNumbers.map(orderNumber => `<li>${orderNumber}</li>`).join('')}</ul>`;

    buefy.dialog.confirm({
      title: 'Pakbonnen downloaden?',
      message,
      cancelText: 'Annuleren',
      confirmText: 'Downloaden',
      type: 'is-success',
      onConfirm: async () => {
        try {
          isLoading.value = true;
          const pdf = await store.dispatch('order/packingSlipDoc', { order_ids: orderIds });
          const date = new Intl.DateTimeFormat('nl-NL', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
          })
            .format(new Date())
            .replace(/[^0-9]/g, '_')
            .replace(/_+/g, '_');
          download(`pakbon_${date}.pdf`, pdf, 'application/pdf', 'base64');
        } catch (error) {
          console.error('Could not generate packing slips', error);
          buefy.toast.open('Er is een fout opgetreden bij het genereren van de pakbonnen.');
        } finally {
          isLoading.value = false;
        }
      },
    });
  };

  onBeforeMount(() => {
    reset();
  });

  defineExpose({
    createOrderLabels,
    printOrDownload,
  });
</script>
