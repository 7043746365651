<template>
  <div id="app">
    <b-navbar fixed-top>
      <!--  shadow> -->
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          <img alt="Innosend" src="./assets/innosend.svg" style="max-height: 2.25rem" />
        </b-navbar-item>
      </template>

      <template v-if="user" #end>
        <b-navbar-item v-if="hasUnasweredTicket" tag="router-link" :to="{ name: 'ticket-list' }">
          <b-tooltip :delay="500" label="Nieuwe reactie op onderzoek" position="is-left" type="is-dark">
            <font-awesome-icon fixed-width icon="envelope" />
          </b-tooltip>
        </b-navbar-item>

        <b-navbar-item tag="router-link" :to="{ name: 'home' }">
          <div style="display: flex; align-items: center; gap: 8px">
            <font-awesome-icon fixed-width icon="shopping-cart" />
            Bestellingen
          </div>
        </b-navbar-item>

        <b-navbar-item v-if="innoscanEnabled" tag="router-link" :to="{ name: 'scan' }">
          <div style="display: flex; align-items: center; gap: 8px">
            <font-awesome-icon fixed-width icon="barcode" />
            Innoscan
          </div>
        </b-navbar-item>

        <b-navbar-dropdown close-on-click right>
          <template #label>
            <div style="display: flex; align-items: center; gap: 8px">
              <font-awesome-icon fixed-width icon="user" />
              {{ user?.organisation?.name || user?.name }}
            </div>
          </template>
          <b-navbar-item
            v-if="user.organisation"
            style="display: flex; align-items: center; gap: 8px"
            tag="router-link"
            :to="{ name: 'account' }"
          >
            <font-awesome-icon fixed-width icon="cogs" />
            Instellingen
          </b-navbar-item>
          <hr class="navbar-divider" />

          <b-navbar-item style="display: flex; align-items: center; gap: 8px" @click="logout">
            <font-awesome-icon fixed-width icon="sign-out" />
            Uitloggen
          </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <template v-if="organisation && organisation.is_active !== false">
      <transition :name="transitionName">
        <router-view />
      </transition>
    </template>

    <main v-else-if="organisation" class="section">
      <div class="container">
        <h1 class="title">Je account is geblokkeerd!</h1>
        <p>
          Je account is geblokkeerd en daardoor kan je Innosend nu niet gebruiken. Neem contact op met de klantenservice
          voor meer informatie.
        </p>
      </div>
    </main>

    <b-modal
      :active="errors.length > 0"
      aria-label="Foutmelding"
      aria-modal
      aria-role="dialog"
      class="global-error-dialog"
      close-button-aria-label="Sluiten"
      :destroy-on-hide="false"
      has-modal-card
      trap-focus
    >
      <template #default="props">
        <global-error-dialog @close="props.close"></global-error-dialog>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import { mapActions, mapState } from 'vuex';
  import GlobalErrorDialog from '@/components/modals/GlobalErrorDialog';
  import moment from 'moment';
  import { CONFIG_INNOSCAN_ENABLED, CONFIG_INTERCOM_APP_ID } from '@/config';

  export default {
    components: { GlobalErrorDialog },
    data() {
      return {
        offlineToast: null,
        transitionName: 'slide-next',
        trialBannerMessage: '',
        intercomBooted: false,
      };
    },
    computed: {
      ...mapState({
        errors: state => state.app.errors,
        shops: state => state.shop.all,
        shopsMeta: state => state.shop.meta,
        couriers: state => state.courier.all,
        couriersMeta: state => state.courier.meta,
        organisation: state => state.account.organisation,
        user: state => state.account.user,
        repliedTickets: state => state.ticket.all.filter(o => o.status !== 'closed' && o.is_replied == true),
        appInitialized: state => state.appInitialized,
      }),

      innoscanEnabled() {
        return CONFIG_INNOSCAN_ENABLED;
      },
      viewName() {
        return this.$route.name;
      },
      hasUnasweredTicket() {
        return this.repliedTickets.length > 0;
      },
    },
    methods: {
      ...mapActions({
        logout: 'auth/logout',
      }),
      getConnectionStatusViaMetadata(meta, list) {
        return meta.reduce((acc, meta) => {
          acc[`has_${meta.name}`] = list.some(item => item.service_name === meta.name) ? 'Yes' : 'No';
          return acc;
        }, {});
      },
      getFinancials() {
        return {
          /** monthly spend is the average revenue per month generated over the lifetime of the organisation */
          monthly_spend: null,
          /** total spend is the total revenue generated over the lifetime of the organisation */
          total_spend: null,

          /** the date of the last payment made by the organisation */
          last_spend: null,
        };
      },
      async handleOnline() {
        await this.$store.dispatch('init');
        if (this.offlineToast) {
          this.offlineToast.close();
        }
      },
      handleOffline() {
        this.offlineToast = this.$buefy.toast.open({
          message: 'Je bent offline, verbind met het internet en probeer het opnieuw of ververs de pagina.',
          type: 'is-warning',
          indefinite: true,
        });
      },
    },
    async created() {
      await this.$store.dispatch('init');
      window.addEventListener('online', this.handleOnline);
      window.addEventListener('offline', this.handleOffline);
      if (!navigator.onLine) {
        this.handleOffline();
      }
    },
    beforeDestroy() {
      window.removeEventListener('online', this.handleOnline);
      window.removeEventListener('offline', this.handleOffline);
    },
    watch: {
      $route(to, from) {
        const toDepth = to.path.split('/').length;
        const fromDepth = from.path.split('/').length;
        this.transitionName = toDepth < fromDepth ? 'slide-next' : 'slide-prev';
      },
      appInitialized(newValue) {
        if (newValue) {
          const connectedAndDisconnectedShops = this.getConnectionStatusViaMetadata(this.shopsMeta, this.shops);
          const connectedAndDisconnectedCouriers = this.getConnectionStatusViaMetadata(
            this.couriersMeta,
            this.couriers,
          );
          const financials = this.getFinancials();

          window.intercomSettings = {
            app_id: CONFIG_INTERCOM_APP_ID, // used to connect to intercom
            api_base: 'https://api-iam.intercom.io', // region specific api base | must find way to switch to EU
            user_id: this.user.id, //enabled us to tie our users to intercom
            user_hash: this.user.intercom_hash,
            name: this.user.name,
            email: this.organisation.contact.email, //switch to user.email when we support multiple organisations/users
            created_at: moment(this.organisation.created_at).unix(), //switch to user.created_at when we support multiple organisations/users
            phone: this.organisation.contact.phone, //switch to user.phone when we support multiple organisations/users
            avatar: {
              type: 'avatar',
              image_url: this.organisation.company_logo_url,
            },
            company: {
              id: this.organisation.id,
              name: this.organisation.name,
              created_at: moment(this.organisation.created_at).unix(),
              phone: this.organisation.contact.phone,
              plan: this.organisation.plan?.name ?? 'Geen',
              ...connectedAndDisconnectedCouriers,
              ...connectedAndDisconnectedShops,
              ...financials,
            },
          };
          window.Intercom('boot');
          this.intercomBooted = true;
        }
      },
    },
  };
</script>

<style lang="scss">
  @import 'styles/all';
</style>
